import { fixedNumberCopy } from '@/utils/number'
import { POSITION_TABLE_LIMIT } from '@/const/production/position/tableLimit'
import { toNum } from '@/utils/string'
import { getIndexInRange } from '@/utils/production/position/formula/getters'

const P_OR_M = '+/- '

// Индекс по диаметру внутренний/наружный.
// dWire - значение из поля 'Диаметр проволоки (d)',
// diameter - значение из поля 'Диаметр внутренний/наружный (D)'.
function indexByD (dWire, diameter, isInner = false) {
  diameter = toNum(diameter)
  if (!diameter) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // i = (D наружный – d проволоки) / d проволоки)
  // i = (D внутренний + d проволоки) / d проволоки)
  const a = isInner ? (diameter + dWire) : (diameter - dWire)
  const i = a / dWire
  return fixedNumberCopy(i, 2)
}

// Допуск по диаметру наружному/внутреннему.
// dWire - значение из поля 'Диаметр проволоки (d)',
// index - значение из поля 'Индекс (i)'.
function diameterLimit (dWire, index) {
  if (!index) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const iI = getIndexInRange(index, POSITION_TABLE_LIMIT.DIAMETER_RANGE.byIndex)
  const dI = getIndexInRange(dWire, POSITION_TABLE_LIMIT.DIAMETER_RANGE.byDWire)

  const limit = POSITION_TABLE_LIMIT.DIAMETER[iI][dI]

  if (index <= 15) return P_OR_M + (limit / 2)
  if (index > 30) return P_OR_M + (limit * 3)
  if (index > 20) return P_OR_M + (limit * 2)
  return P_OR_M + limit
}

// Допуск по длине.
// dWire - значение из поля 'Диаметр проволоки (d)',
// turns - значение из поля 'Число рабочих витков (n)',
// step - значение из поля 'Шаг (t)'.
function bodyLengthLimit ({ dWire, turns, step }) {
  step = toNum(step)
  if (!step) return ''

  turns = toNum(turns)
  if (!turns) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // s’3/d = (Шаг (t) – d проволоки) / d проволоки
  const s3d = (step - dWire) / dWire

  const s3dI = getIndexInRange(s3d, POSITION_TABLE_LIMIT.BODY_LENGTH_RANGE.byS3D)
  const dI = getIndexInRange(dWire, POSITION_TABLE_LIMIT.BODY_LENGTH_RANGE.byDWire)

  const c1 = POSITION_TABLE_LIMIT.BODY_LENGTH[s3dI][dI]

  // limit = с1 * Число рабочих витков (n) / 2
  let limit = c1 * turns / 2
  limit = fixedNumberCopy(limit)
  return P_OR_M + limit
}

// Допуск по длине для типа "Пружины растяжения".
// dWire - значение из поля 'Диаметр проволоки (d)',
// turns - значение из поля 'Число рабочих витков (n)',
function bodyLengthLimitForType2 ({ dWire, turns }) {
  turns = toNum(turns)
  if (!turns) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const dI = getIndexInRange(dWire, POSITION_TABLE_LIMIT.BODY_LENGTH_RANGE_TYPE2.byDWire)
  const tI = getIndexInRange(turns, POSITION_TABLE_LIMIT.BODY_LENGTH_RANGE_TYPE2.byTurns)
  const wDT = POSITION_TABLE_LIMIT.WIRE_DIAMETER_TOLERANCE[dI]
  let c1 = POSITION_TABLE_LIMIT.BODY_LENGTH_TYPE2[tI][dI]

  if (tI === 4) {
    c1 = turns * c1
  }

  // limit = ((с1 * 2) * (d проволоки + допуск на диаметр) + (Число рабочих витков (n) + допуск на диаметр) * допуск на диаметр) / 2
  let limit = ((c1 * 2) * (dWire + wDT) + (turns + wDT) * wDT) / 2
  // console.log(c1, wDT, limit)
  limit = fixedNumberCopy(limit, 2)

  return P_OR_M + limit
}

// Допуск по длине.
// bodyLength - значение из поля 'Длина тела (L0)'.
function bodyLengthLimitByLength (bodyLength) {
  bodyLength = toNum(bodyLength)
  if (!bodyLength) return ''

  const i = getIndexInRange(bodyLength, POSITION_TABLE_LIMIT.BODY_LENGTH_RANGE_359.byLength)

  const limit = POSITION_TABLE_LIMIT.BODY_LENGTH_359[i]

  return limit
}

// Шаг.
// dWire - значение из поля 'Диаметр проволоки (d)',
// rt1Id - значение ID из поля 'Опорный виток 1',
// rt2Id - значение ID из поля 'Опорный виток 2',
// turns - значение из поля 'Число рабочих витков (n)',
// bodyLength - значение из поля 'Длина тела (L0)'.
function step ({ dWire, rt1Id, rt2Id, turns, bodyLength }) {
  if (!rt1Id || !rt2Id) return ''

  turns = toNum(turns)
  if (!turns) return ''

  bodyLength = toNum(bodyLength)
  if (!bodyLength) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const rt1 = getIndexByReferenceTurns(rt1Id)
  const rt2 = getIndexByReferenceTurns(rt2Id)

  // t = (Длина тела (L0) – ((x1+х2) * d проволоки)) / Число рабочих витков (n)
  const t = (bodyLength - ((rt1 + rt2) * dWire)) / turns
  return fixedNumberCopy(t, 2)

  function getIndexByReferenceTurns (id) {
    switch (id) {
      case 1:
        return 0.75
      case 3:
      case 6:
        return 1.5
      case 2:
      case 5:
        return 0.5
      default: return 0
    }
  }
}

// Масса
// dWire - значение из поля 'Диаметр проволоки (d)',
// sweepLength - значение из поля 'Длина развертки',
// density - значение из поля 'Материал' (плотность).
function weight ({ dWire, sweepLength, density }) {
  density = toNum(density)
  if (!density) return ''

  sweepLength = toNum(sweepLength)
  if (!sweepLength) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const value = (3.14 * sweepLength * Math.pow(dWire, 2) * density) / (4 * Math.pow(10, 9))

  return fixedNumberCopy(value)
}

// Длина развертки по типу зацепа.
// dWire - значение из поля 'Диаметр проволоки (d)',
// dOuter - значение из поля 'Диаметр наружный (D)',
// turns - значение из поля 'Число рабочих витков (n)',
// ht1Id - значение ID из поля 'Тип зацепа 1',
// ht2Id - значение ID из поля 'Тип зацепа 2',
// hl1 - значение из поля 'Длина зацепа 1',
// hl2 - значение из поля 'Длина зацепа 2',
// hg1 - значение из поля 'Зазор зацепа 1',
// hg2 - значение из поля 'Зазор зацепа 2',
function sweepLengthByHookType ({ dWire, dOuter, turns, ht1Id, ht2Id, hl1, hl2, hg1, hg2 }) {
  if (!ht1Id || !ht2Id) return ''

  turns = toNum(turns)
  if (!turns) return ''

  dOuter = toNum(dOuter)
  if (!dOuter) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  hl1 = toNum(hl1)
  hg1 = toNum(hg1)
  if (ht1Id === 8 && !hl1 && !hg1) return ''

  hl2 = toNum(hl2)
  hg2 = toNum(hg2)
  if (ht2Id === 8 && !hl2 && !hg2) return ''

  const xy1 = getXYbyHookType(ht1Id, hl1, hg1)
  const xy2 = getXYbyHookType(ht2Id, hl2, hg2)

  // l = 3,14 * (D наружный – d проволоки) * (Число рабочих витков (n) + (х1 + х2)) + y1 + y2
  const a = dOuter - dWire
  const b = turns + xy1.x + xy2.x
  const l = 3.14 * a * b + xy1.y + xy2.y
  return fixedNumberCopy(l, 2)

  function getXYbyHookType (id, hl, hg) {
    switch (id) {
      // №0: Без зацепов
      // №0: Без зацепа + шлифован
      case 2:
      case 3: return { x: 0, y: 0 }
      // №1: Зацеп из полного витка выведенный на центр
      // №3: Зацеп из полного витка по касательной (под 90 гр.)
      // №4: Зацеп из полного витка (под 60 гр.)
      case 4:
      case 6:
      case 7: return { x: 1, y: 0 }
      // №1: Зацеп из полного витка выведенный на центр (двойное кольцо)
      // №6: Прямоугольный зацеп
      // №7: Треугольный зацеп
      // №8: Фасонный зацеп
      // №9: Малое двойное кольцо выведенный на центр
      // №10: Вытянутый зацеп с малым крюком
      // №11: Малое кольцо по касательной (90 град.)
      // №12: Зацеп с плавающим крючком
      // №13: Зацеп в обратную
      case 18:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16: return { x: 2, y: 0 }
      // №2: Зацеп из половины витка выведенный на центр
      case 5: return { x: 0.5, y: 0 }
      // №14: Французский зацеп
      case 17: return { x: 1.25, y: 0 }
      // №5: Вытянутый зацеп (крючковатого типа)
      case 8: {
        // Y при №5 = ((2 * Длина зацепа) – D наружный) + (D наружный * 3,14 * 0,5) – Зазор зацепа
        const a = (2 * hl) - dOuter
        const b = dOuter * 3.14 * 0.5
        const y = (a + b) - hg
        return { x: 0, y: y }
      }
      default: return { x: 0, y: 0 }
    }
  }
}

// Длина развертки по длине отвода и внутреннему диаметру.
// dWire - значение из поля 'Диаметр проволоки (d)',
// dInner - значение из поля 'Диаметр внутренний (D)',
// turns - значение из поля 'Число рабочих витков (n)',
// tapLength1 - значение из поля 'Длина отвода 1',
// tapLength2 - значение из поля 'Длина отвода 2',
function sweepLengthByTapLengthAndDInner ({ dWire, dInner, turns, tapLength1, tapLength2 }) {
  tapLength1 = toNum(tapLength1)
  if (!tapLength1) return ''

  tapLength2 = toNum(tapLength2)
  if (!tapLength2) return ''

  turns = toNum(turns)
  if (!turns) return ''

  dInner = toNum(dInner)
  if (!dInner) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // l = 3,14 * (D внутренний + d проволоки) * Число рабочих витков(n) + Длина отвода 1 + Длина отвода 2
  const a = dInner + dWire
  const l = 3.14 * a * turns + tapLength1 + tapLength2

  return fixedNumberCopy(l, 2)
}

// Длина развертки по длине отвода и наружному диаметру.
// dWire - значение из поля 'Диаметр проволоки (d)',
// dOuter - значение из поля 'Диаметр наружный (D)',
// turns - значение из поля 'Число рабочих витков (n)',
// tapLength1 - значение из поля 'Длина отвода 1',
// tapLength2 - значение из поля 'Длина отвода 2',
function sweepLengthByTapLengthAndDOuter ({ dWire, dOuter, turns, tapLength1, tapLength2 }) {
  tapLength1 = toNum(tapLength1)
  if (!tapLength1) return ''

  tapLength2 = toNum(tapLength2)
  if (!tapLength2) return ''

  turns = toNum(turns)
  if (!turns) return ''

  dOuter = toNum(dOuter)
  if (!dOuter) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // l = 3,14 * (D наружный - d проволоки) * Число рабочих витков(n) + Длина отвода 1 + Длина отвода 2
  const a = dOuter - dWire
  const l = 3.14 * a * turns + tapLength1 + tapLength2

  return fixedNumberCopy(l, 2)
}

// Длина развертки по внутреннему/наружному диаметру.
// dWire - значение из поля 'Диаметр проволоки (d)',
// diameter - значение из поля 'Диаметр внутренний (D)' / 'Диаметр наружный (D)',
// turns - значение из поля 'Число рабочих витков (n)' / 'Число полных витков (n1)',
function sweepLengthByD ({ dWire, diameter, turns, isInner }) {
  turns = toNum(turns)
  if (!turns) return ''

  diameter = toNum(diameter)
  if (!diameter) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // l = 3,14 * (D наружный – d проволоки) * Число полных витков (n1)
  // l = 3,14 * (D внутренний + d проволоки) * (Число рабочих витков (n) + 2)
  const a = isInner ? (diameter + dWire) : (diameter - dWire)

  const b = isInner ? turns + 2 : turns
  const l = 3.14 * a * b

  return fixedNumberCopy(l, 2)
}

// Количество пробников.
// dWire – значение из поля 'Диаметр проволоки',
// countSpring – количество штук в Позиции.
function probes (dWire, countSpring) {
  const count = +countSpring
  if (!count || count < 3) return 0

  dWire = toNum(dWire)
  if (!dWire) return 0

  const probesCount = {
    small: 1,
    medium: 3,
    big: 5
  }

  if (dWire < 8) {
    if (count >= 15 && count < 50) return probesCount.small
    if (count >= 50 && count < 100) return probesCount.medium
    if (count >= 100) return probesCount.big
  }

  if (count >= 3 && count < 100) return probesCount.small
  if (count >= 100 && count < 300) return probesCount.medium
  if (count >= 300) return probesCount.big

  return 0
}

// Перпендикулярность.
// bodyLength - значение из поля 'Длина тела (L0)'
function perpendicularity (bodyLength) {
  bodyLength = toNum(bodyLength)
  if (!bodyLength) return ''

  // L0 * 0,04
  const num = bodyLength * 0.04
  return `${fixedNumberCopy(num, 2)}`
}

// Допуск по виткам.
// turns - значение из поля 'Число полных витков (n1)',
// dWire - значение из поля 'Диаметр проволоки'.
function turnsLimit (turns, dWire) {
  turns = toNum(turns)
  if (!turns) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const tI = getIndexInRange(turns, POSITION_TABLE_LIMIT.TURNS_RANGE.byTurns)
  const dI = getIndexInRange(dWire, POSITION_TABLE_LIMIT.TURNS_RANGE.byDWire)

  let limit = POSITION_TABLE_LIMIT.TURNS[tI][dI]

  if (tI === 4) {
    limit = turns * limit
    limit = fixedNumberCopy(limit, 2)
  }

  return P_OR_M + limit
}

// Допуск по виткам для типа "Пружины сжатия".
// turns - значение из поля 'Число полных витков (n1)',
// dWire - значение из поля 'Диаметр проволоки'.
function turnsLimitType1 ({ turns, dWire }) {
  turns = toNum(turns)
  if (!turns) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  const dI = getIndexInRange(dWire, POSITION_TABLE_LIMIT.TURNS_RANGE_TYPE1.byDWire)
  const tI = getIndexInRange(turns, POSITION_TABLE_LIMIT.TURNS_RANGE_TYPE1.byTurns)
  let limit = POSITION_TABLE_LIMIT.TURNS_TYPE1[tI][dI]

  if (tI === 4) {
    limit = turns * limit
  }

  limit = fixedNumberCopy(limit, 3)

  return P_OR_M + limit
}

// Допуск на шаг.
// step - значение из поля 'Шаг (t)',
// dWire - значение из поля 'Диаметр проволоки (d)'.
function stepLimit (step, dWire) {
  step = toNum(step)
  if (!step) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  // 0,15 * (t – d)
  const value = 0.15 * (step - dWire)
  return P_OR_M + fixedNumberCopy(value, 2)
}

// Жесткость, Н/мм.
// dWire - значение из поля 'Диаметр проволоки (d)',
// dOuter - значение из поля 'Диаметр наружный (D)',
// turns - значение из поля 'Число рабочих витков (n)',
// material - значение из поля 'Материал'.
function stiffness ({ dWire, dOuter, turns, material }) {
  if (!material) return ''

  dWire = toNum(dWire)
  if (!dWire) return ''

  dOuter = toNum(dOuter)
  if (!dOuter) return ''

  turns = toNum(turns)
  if (!turns) return ''

  // с = ('модуль упругости' * d^4) / (8 * (D - d)^3 * n)

  const a = getElasticModulus(material) * (dWire ** 4)
  const b = (dOuter - dWire) ** 3
  const c = 8 * b * turns
  const result = a / c
  return fixedNumberCopy(result, 2)

  // Модуль упругости стали.
// name - название материала
  function getElasticModulus (name) {
    switch (name) {
      case 'Нерж. EN 10270-3/1.4310': return 70000
      case '51ХФА ГОСТ 1071-81': return 83000
      case '60С2А ГОСТ 14963-78':
      case '60С2А ГОСТ 14959-79': return 82000
      case '65Г ГОСТ 14959-79': return 84000
      default: return 78500
    }
  }
}

// Допуск на длину отвода.
// tapLength - значение из поля 'Длину отвода'
function tapLengthLimit (tapLength) {
  tapLength = toNum(tapLength)
  if (!tapLength) return ''

  const i = getIndexInRange(tapLength, POSITION_TABLE_LIMIT.TAP_LENGTH_RANGE)

  let limit = POSITION_TABLE_LIMIT.TAP_LENGTH[i]

  limit = fixedNumberCopy(limit / 2, 2)
  return P_OR_M + limit
}

export const POSITION_FORMULAS = {
  bodyLengthLimit,
  bodyLengthLimitByLength,
  bodyLengthLimitForType2,
  diameterLimit,
  indexByD,
  perpendicularity,
  probes,
  stepLimit,
  stiffness,
  sweepLengthByD,
  sweepLengthByHookType,
  sweepLengthByTapLengthAndDInner,
  sweepLengthByTapLengthAndDOuter,
  tapLengthLimit,
  turnsLimit,
  turnsLimitType1,
  step,
  weight
}
